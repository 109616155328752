
// @ts-nocheck
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
export default class DenemeTahtasi extends Vue {
  response: any = null;

  execCrypto(){
    this.response = this.$uyapHelper.hash({deneme: 'asdasdas'});
  }

  imzala() {
    let udfbase64 = `UEsDBBQACAgIAMkQZ1UAAAAAAAAAAAAAAAALAAAAY29udGVudC54bWyFk8uO2jAUhvd5CtdrCIRplVkkGdEyVKOqINGwGCGEXHKSuPUlsg1zeaF5g67Y5cXmJFyGGRZd5eTIx///f7ajm0cpyBaM5VrFNPD7lIBa64yrIqbzdNy9puQmIZ4XOZCVYA5Iro1kbsWzZn1ISeJFa60cKJdEnxbfRsN0uBiBAgkdUta7v/VO2nr3nNU743vecplEvdP6yugKjONgsWYFjNu9iYSMs1/8GSZMAspQIiB3P5kpOLoM+/51+IUSw4vyoul09bH1Wzun5cduxVB5ajj6YG6fnpISWAZmPM1zCy6mg34DJNfaXTR7GOPMvReBwMTKWWLAaoFIY1puRTeDnG2EazFVzLDCsKpMjsSIdcy44879JqcqXIkiwUHibeR/04Pgbfxi2ot6R4NYW/ckGuTtl6gW8sloBnZteLVn8h3qFzCCIwUmuXiK6YgzoQtKLB4PCg0avgLvQs6EBUq4Y4KvT7/j6SRdDdN0dvd1nt6uftzex/QP27JH3z7gHfPxSuX+GOPM72bIbWPWsDgo7YU6rbtD3fqNcYarTqsfDJbN+RgojN4odNENrj4PgjC8agGc53t3GMcwKZdgyQQeyExLps5TvcdQ/9tmsId6gIdEj08i8V4BUEsHCACC7aXYAQAASAMAAFBLAQIUABQACAgIAMkQZ1UAgu2l2AEAAEgDAAALAAAAAAAAAAAAAAAAAAAAAABjb250ZW50LnhtbFBLBQYAAAAAAQABADkAAAARAgAAAAA=`
    let blob = this.$uyapHelper.base64toBlob(udfbase64, 'xml');
    console.log(blob);
    this.$imza.dokumanImzala(blob);
  }

  bildirim() {
    this.$toast.warning('Bu bir bildirim örneğidir...')
  }

  async execBagkur() {
    this.response = await this.$uyap.DosyaBorcluBagkur().run({dosyaId: 123, kisiKurumId: 123, sorguBakiye: 123});
  }

  async execBorcluTakbis() {
    this.response = await this.$uyap.BorcluTakbisSorgulama().run({
      dosyaId: 123312,
      kisiKurumId: 123123
    });
  }

  async execBorcluPostaCeki() {
    this.response = await this.$uyap.BorcluPostaCeki().run({dosyaId: 123, kisiKurumId: 123, sorguBakiye: 123});
  }

  async execBorcluSgkHaciz() {
    this.response = await this.$uyap.BorcluSgkHaciz().run({dosyaId: 123, kisiKurumId: 123});
  }

  async execBorcluIski() {
    //this.response = await this.$uyap.BorcluIski().parse("");
    this.response = await this.$uyap.BorcluIski().run({dosyaId: 66154994, kisiKurumId: 30660481, sorguBakiye: 1});
  }

  async execBorcluDib() {
    this.response = await this.$uyap.BorcluDib().run({dosyaId: 123, kisiKurumId: 123, sorguBakiye: 123});
  }

  async execBorcluGib() {
    this.response = await this.$uyap.BorcluGib().run({dosyaId: 123, kisiKurumId: 123, sorguBakiye: 123});
  }

  async execBorcluGsm() {
    this.response = await this.$uyap.BorcluGsm().run({dosyaId: 123, kisiKurumId: 123, sorguBakiye: 123});
  }

  async execBorcluIcraDosyasi() {
    this.response = await this.$uyap.BorcluIcraDosyasi().run({dosyaId: 123, kisiKurumId: 123});
  }

  async execDosyaBorcluMernis() {
    this.response = await this.$uyap.DosyaBorcluMernis().run({dosyaId: 123, kisiKurumId: 123, sorguBakiye: 123});
  }

  async execAvukatYetkiliKurumlar() {
    this.response = await this.$uyap.AvukatYetkiliKurumlar().run({});
  }

  async execBankaHacziParams() {
    this.response = await this.$uyap.BankaHacziParams().run({});
  }

  async execAvukatKisiselBilgileriSorgulama() {
    this.response = await this.$uyap.AvukatKisiselBilgileriSorgulama().run({})
  }

  async execAvukatIcraTalepEvrakiGonder() {
    this.response = await this.$uyap.AvukatIcraTalepEvrakiGonder().run({formData: new FormData()})
  }

  async execAvukatDosyaSorgulaGenel() {
    // this.response = await this.$uyap.AvukatDosyaSorgulaGenel().run({
    //   yargiTuru: 123,
    //   yargiBirimi: 123,
    //   dosyaYil: null,
    //   dosyaSira: null,
    //   baslangicTarihi: 'asd',
    //   bitisTarihi: 'asd',
    //   dosyaKapanisBaslangisTarihi: 'asd',
    //   dosyaKapanisBitisTarihi: 'asd',
    //   dosyaKapaliMi: false,
    //   mahkeme: 'asd',
    //   pageCount: 123,
    //   pageSize: 123
    // })
  }

  async execAvukatSorgulama() {
    this.response = await this.$uyap.AvukatSorgulama().run({
      baroNo: '123',
      ilKodu: '35',
      tbbNo: '123',
      tcKimlikNo: '11111111110'
    });
  }

  async execAvukatTalepList() {
    this.response = await this.$uyap.AvukatTalepList().run({dosyaId: 123, islemTuru: 'haciz'});
  }

  async execAdliyeler() {
    this.response = await this.$uyap.Adliyeler().run({ilKodu: 34})
  }

  async execAracHacziParams() {
    this.response = await this.$uyap.AracHacziParams().run({})
  }

  async execDosyaBorcluEgm() {
    this.response = await this.$uyap.DosyaBorcluEgm().run({dosyaId: 123, kisiKurumId: 123, sorguBakiye: 123})
  }

  async execDosyaBorcluEgmMahrumiyet() {
    this.response = await this.$uyap.DosyaBorcluEgmMahrumiyet().run({dosyaId: 123, kisiKurumId: 123, plaka: '35'})
  }

  async execDosyaBorcluIsyeriSgk() {
    this.response = await this.$uyap.DosyaBorcluIsyeriSgk().run({dosyaId: 123, kisiKurumId: 123, sorguBakiye: 123})
  }

  async execDosyaBorclulari() {
    this.response = await this.$uyap.DosyaBorclulari().run({dosyaId: 123})
  }

  async execDosyaBorcluSSK() {
    this.response = await this.$uyap.DosyaBorcluSSK().run({dosyaId: 123, kisiKurumId: 123, sorguBakiye: 123})
  }

  async execDosyaDetayBilgileri() {
    this.response = await this.$uyap.DosyaDetayBilgileri().run({dosyaId: 123})
  }

  async execDosyaEvraklar() {
    this.response = await this.$uyap.DosyaEvraklar().run({dosyaId: 123})
  }

  async execDosyaHarcMasrafHesabi() {
    this.response = await this.$uyap.DosyaHarcMasrafHesabi().run({tcKimlikNo: 11111111110})
  }

  async execDosyaHesapBilgileri() {
    this.response = await this.$uyap.DosyaHesapBilgileri().run({dosyaId: 123})
  }

  async execDosyaKapatmaNedenleri() {
    this.response = await this.$uyap.DosyaKapatmaNedenleri().run({dosyaTurKod: 1})
  }

  async execDosyaOdemeIslemleri() {
    this.response = await this.$uyap.DosyaOdemeIslemleri().run({
      dosyaId: 123,
      harcMasrafTipi: 'asd',
      harcMasrafList: 'asd',
      postaMasraflariList: [],
      odemeTipi: 123,
      uyapId: 'asd',
      vakifbankHesapBilgileri: 'asd'
    })
  }

  async execDosyaSafahat() {
    this.response = await this.$uyap.DosyaSafahat().run({dosyaId: 123, token: 123})
  }

  async execDosyaTarafBilgileri() {
    this.response = await this.$uyap.DosyaTarafBilgileri().run({dosyaId: 123})
  }

  async execGetDosyaTarafBilgileri() {
    this.response = await this.$uyap.GetDosyaTarafBilgileri().run({dosyaId: 123})
  }

  async execGetDosyaTarafListesiWithTarafRol() {
    this.response = await this.$uyap.GetDosyaTarafListesiWithTarafRol().run({dosyaId: 123, islemTuru: 1})
  }

  async execGetEvrakBlob() {
    this.response = await this.$uyap.EvrakIndir().run({evrakId: 123, dosyaId: 123, yargiTuru: 1})
  }

  async execGetIbanDetails() {
    this.response = await this.$uyap.GetIbanDetails().run({iban: "1111111111111111110"})
  }

  async execGetKurumMersisAdresi() {
    this.response = await this.$uyap.GetKurumMersisAdresi().run({mersisNo: '123', vergiNo: '123'})
  }

  async execGetMaasHacziParams() {
    this.response = await this.$uyap.GetMaasHacziParams().run({})
  }

  async execGetPortalAvukatTalepAdresTuruList() {
    this.response = await this.$uyap.GetPortalAvukatTalepAdresTuruList().run({})
  }

  async execGonderilebilecekEvrakListesi() {
    this.response = await this.$uyap.GonderilebilecekEvrakListesi().run({dosyaId: 123})
  }

  async execIcraPesinHarcGetir() {
    this.response = await this.$uyap.IcraPesinHarcGetir().run({dosyaId: 123})
  }

  async execIcraTakipIlamDosyasiUygunMu() {
    this.response = await this.$uyap.IcraTakipIlamDosyasiUygunMu().run({
      ilamTuru: 123,
      birimId: 123,
      dosyaSiraNo: 123,
      dosyaTurKod: 123,
      dosyaYil: '2022',
      TarafList: 'asd'
    })
  }

  async execIcraTalepEvrakHazirla() {
    this.response = await this.$uyap.IcraTalepEvrakHazirla().run({
      talepBilgileri: [],
      dosyaEsasNo: '123',
      dosyaId: 123,
      kisiKurumId: 123
    })
  }

  async execKisiMernisAdresiVarmi() {
    this.response = await this.$uyap.KisiMernisAdresiVarmi().run({tcKimlikNo: 11111111110})
  }

  async execKisiSorgula() {
    this.response = await this.$uyap.KisiSorgula().run({tcKimlikNo: '11111111110'})
  }

  async execKurumSorgulama() {
    this.response = await this.$uyap.KurumSorgulama().run({
      kurumAdi: 'asd',
      detsisNo: '123',
      mersisNo: '123',
      vergiNo: '123'
    })
  }

  async execMahkemeBirimleri() {
    this.response = await this.$uyap.MahkemeBirimleri().run({yargiTuru: 123, yargiBirimi: 123, dosyaKapaliMi: 'asd'})
  }

  async execOdemeYap() {
    this.response = await this.$uyap.OdemeYap().run({
      odemeTipi: 123,
      dosyaId: 123,
      uyapId: 123,
      kisiKurumId: 123,
      vakifbankHesapBilgileri: 123
    })
  }

  async execParaBirimleri() {
    this.response = await this.$uyap.ParaBirimleri().run({})
  }

  async execPortalAvukatTalepMalTuruList() {
    this.response = await this.$uyap.PortalAvukatTalepMalTuruList().run({})
  }

  async execPortalAvukatTalepTebligatTuruList() {
    this.response = await this.$uyap.PortalAvukatTalepTebligatTuruList().run({})
  }

  async execSgkKamu() {
    this.response = await this.$uyap.SgkKamu().run({dosyaId: 123, kisiKurumId: 123, sorguBakiye: 123})
  }

  async execTahsilatReddiyat() {
    this.response = await this.$uyap.TahsilatReddiyat().run({dosyaId: 123, dosyaTurKod: 1})
  }

  async execTahsilHarcOranlariniGetir() {
    this.response = await this.$uyap.TahsilHarcOranlariniGetir().run({})
  }

  async execTakibiKesinlesenBorcluListesi() {
    this.response = await this.$uyap.TakibiKesinlesenBorcluListesi().run({dosyaId: 123})
  }

  async execTamamlanmayanDosyalar() {
    this.response = await this.$uyap.TamamlanmayanDosyalar().run({dosyaTurKod: 1})
  }
}
